//import angular from 'angular';
import * as _ from 'underscore';
import FileManagement from '../../../services/fileManagement';
import OcDateSvc from '../../../services/ocDateSvc';
import ReportSvc from '../../../services/reportSvc';
import AdminUrls from '../../admin/adminUrls';
import tmpl from './changeStatusPopup.html';


const enum NdtStatus {
    fail = 0,
    pass = 1
}

class changeStatusPopupCtrl implements ng.IController, IWindowController {

    wnd: IWindowObj;
    form: ng.IFormController;

    buttons: IButton[];
    buttonStates: { [key: string]: IButtonState };
    buttonMethods: { [key: string]: (button: string) => void }

    initParams: any = {};

    status: ScreenStatus = ScreenStatus.loading;

    // component properties
    equipmentId: guid;
    equipmentEventId: guid;
    siteId: guid;
    clientId: guid;
    bulkMoveTargetStatusCategory: string; // TO-DO: enum?
    defaultEventDate: Date;

    initialPurchaseOrderId: guid;

    // flags
    readonly: boolean;

    isEdit: boolean;
    isBulkMove: boolean;
    isChangeStatus: boolean;
    generatingClaimNumber: boolean;

    get displayEditMode() { return (this.isEdit || this.isBulkMove || this.isChangeStatus) }

    // layout
    labelColSpan = 4;
    rightBufferColSpan = 2;
    dataColSpan = 6;

    get primaryColSpan() { return this.displayEditMode ? 6 : 12 }

    // config
    enforceStoreLocation = this.ocConfigSvc.user.site.settings.tyreMaintenance.storeLocation;
    allowClaimNumberAutoGeneration = this.ocConfigSvc.user.site.settings.maintenanceAndWarranty.claimNumber.allowAutoGenerate;
    showPurchaseOrder = this.ocConfigSvc.user.site.settings.purchaseOrdersEnforceForNewReceive != ConfigurationOption.hide;
    needPurchaseOrder = this.ocConfigSvc.user.site.settings.purchaseOrdersEnforceForNewReceive == ConfigurationOption.mandatory;
    canViewComponentFailureReport: boolean;

    // controls
    damageCauseControl: any = {};
    damageLocationControl: any = {};
    damageSourceControl: any = {};

    // reference data
    locations: any[];
    damageCauses: any[];
    damageLocations: any;
    damageLocations_all: any[];
    damageSources: any[];
    damageSources_all: any[];
    destinations: any[];
    statusTypes: any[];
    returnToSupplierReasons: IReturnToSupplierReason[];
    retreaders: IRetreader[];
    repairers: IRepairer[];
    suppliers: ISupplier[];
    currencyTypes: ICurrency[];
    repairTypes: IRepairType[];
    purchaseTypes: IPurchaseType[];

    ndtStatuses = [{ key: NdtStatus.pass, name: this.amtXlatSvc.xlat('common.pass') }, { key: NdtStatus.fail, name: this.amtXlatSvc.xlat('common.fail') }];

    costReadOnly = undefined;

    // details
    existingStatusDetails: IChangeStatusDetails;
    statusDetails: IChangeStatusDetails;

    combinedDateTime: Date = null;

    acceptedFileTypes: string[];

    get hasHoursSinceLastNDT() { return this.statusDetails.hoursSinceLastNdt != null; }

    get hasNdtReading() { return this.statusDetails.ndt != null; }

    // currency type
    associatedCostCurrencyTypeRequired = false;

    static $inject = ['$scope', '$rootScope', 'amtCommandQuerySvc', 'confirmSvc', 'enums', 'amtXlatSvc', 'componentUrls', 'referenceDataUrls', '$stateParams',
        'adminUrls', 'WindowFactory', 'ocConfigSvc', '$timeout', 'notifySvc', 'dataBroker', 'errorReporter', 'ocDateSvc', 'fileManagement',
        'amtConstants', 'ocSecuritySvc', 'equipmentUrls', 'helperSvc', 'reportSvc'];

    constructor(private $scope: ng.IScope, private $rootScope: ng.IRootScopeService, private amtCommandQuerySvc: IAmtCommandQuerySvc, private confirmSvc: IConfirmSvc, private enums: any,
        private amtXlatSvc: IAmtXlatSvc, private componentUrls: any, private referenceDataUrls: any, private $stateParams: ng.ui.IStateParamsService,
        private adminUrls: AdminUrls, private WindowFactory: IWindowFactory, private ocConfigSvc: any, private $timeout: ng.ITimeoutService, private notifySvc: INotifySvc,
        private dataBroker: IDataBroker, private errorReporter: IErrorReporter, private ocDateSvc: OcDateSvc, private fileManagement: FileManagement,
        private amtConstants: IAmtConstants, private ocSecuritySvc: IOcSecuritySvc, private equipmentUrls: IEquipmentUrls,
        private helperSvc: IHelperSvc, private reportSvc: ReportSvc) {

        if (this.wnd) {
            // pass along dirty flag to the window for use on closing minimised windows
            this.$scope.$watch(() => this.form.$dirty, () => { this.wnd.isDirty = this.form.$dirty });
        }

        this.$scope.$watch('vm.statusDetails.eventDate', async (newValue: Date, oldValue: Date) => {

            if ((!this.isEdit || (this.isEdit && oldValue)) && newValue && !this.isBulkMove && !ocDateSvc.approxEqual(newValue, oldValue)) {

                this.wnd.processing = true;

                try {
                    await this.loadReferenceData(true)
                } catch (error) {
                    this.errorReporter.logError(error, 'ChangeStatus-LoadReferenceData');
                } finally {
                    this.wnd.processing = false;
                }
            }
        });

        // on selection changed
        this.$scope.$watch('vm.statusDetails.statusType', async (newValue: IStatusType, oldValue: IStatusType) => {

            if (this.statusDetails && ((oldValue && oldValue.key) || (!this.isEdit && !this.isBulkMove)) && oldValue != newValue) {

                this.wnd.processing = true;

                try {

                    let optionCleared = !newValue;

                    if (optionCleared || !newValue.requiresDamageReason) {
                        this.clearDamageReason();
                    }

                    if (optionCleared || !newValue.allowsDepthReading) {
                        this.statusDetails.remainingDepthInner = null;
                        this.statusDetails.remainingDepthOuter = null;
                    }

                    if (optionCleared || !newValue.allowsMWClaim) {
                        this.statusDetails.hasWarrantyClaim = false;
                        this.statusDetails.moveDetails.warrantyClaim = null;
                    }

                    if (optionCleared || !newValue.allowsReturnToSupplierReason) {
                        this.statusDetails.moveDetails.returnToSupplierReason = null;
                        this.statusDetails.moveDetails.returnToSupplierReasonOther = null;
                    }

                    if (optionCleared || !newValue.allowsRetreader) {
                        this.statusDetails.moveDetails.retreader = null;
                        this.statusDetails.moveDetails.retreaderOther = null;
                    }

                    if (optionCleared || !newValue.allowsRepairer) {
                        this.statusDetails.moveDetails.repairer = null;
                        this.statusDetails.moveDetails.repairerOther = null;
                    }

                    if (optionCleared || !newValue.allowsSupplier) {
                        this.statusDetails.moveDetails.supplier = null;
                        this.statusDetails.moveDetails.supplierOther = null;
                    }

                    if (optionCleared || !newValue.allowsRepairType) {
                        this.statusDetails.moveDetails.repairType = null;
                    }

                    if (optionCleared || (!newValue.receivesCredit && !newValue.requiresCredit)) {
                        this.statusDetails.moveDetails.associatedCost = null;
                    } else if (!this.statusDetails.moveDetails.associatedCost) {
                        this.statusDetails.moveDetails.associatedCost = {
                            currencyType: {
                                id: this.ocConfigSvc.user.site.currency.id,
                                symbol: this.ocConfigSvc.user.site.currency.symbol,
                                decimalPlaces: this.ocConfigSvc.user.site.currency.decimalPlaces
                            },
                            amount: 0
                        };
                    }

                    // clear locations
                    this.statusDetails.moveDetails.location = null;
                    this.locations = null;

                    // get reference data
                    await this.loadReferenceData();

                    if (this.statusDetails.moveDetails.damageCauseId) {
                        if (this.damageLocations_all) {
                            this.damageLocations = _.filter(this.damageLocations_all, dl => { return dl.damageCauseId === this.statusDetails.moveDetails.damageCauseId; });
                        }

                        if (this.damageSources_all) {
                            this.damageSources = _.filter(this.damageSources_all, ds => { return ds.damageCauseId === this.statusDetails.moveDetails.damageCauseId;; });
                        }
                    }

                } catch (error) {
                    errorReporter.logError(error, 'ChangeStatus-LoadReferenceData');
                } finally {
                    this.wnd.processing = false;
                }
            }
        });

        this.$scope.$watch('vm.statusDetails.moveDetails.returnToSupplierReason', (newValue: IReturnToSupplierReason, oldValue: IReturnToSupplierReason) => {

            let wipeDamageReason = (oldValue && !newValue) || (newValue && !newValue.requiresDamageReason);
            let wipeReturnToSupplierReasonOther = (oldValue && !newValue) || (newValue && !newValue.requiresManualEntry);

            if (wipeDamageReason)
                this.clearDamageReason();

            if (wipeReturnToSupplierReasonOther)
                this.statusDetails.moveDetails.returnToSupplierReasonOther = null;

            if (!this.damageCauses && newValue && newValue.requiresDamageReason)
                this.loadReferenceData();

        });

        this.$scope.$watch('vm.statusDetails.moveDetails.associatedCost.amount', () => {
            this.associatedCostCurrencyTypeRequired = !!this.statusDetails && !!this.statusDetails.moveDetails
                && !!this.statusDetails.moveDetails.associatedCost && !!this.statusDetails.moveDetails.associatedCost.amount;
        });

        this.$scope.$watchGroup([() => this.form.$invalid, () => this.form.$pristine, () => this.wnd.processing, () => this.readonly], () => {
            this.evaluateButtons();
        });

    } //TODO: end of constructor, refactor this to make it not so loooong

    async $onInit() {

        this.readonly = !this.ocSecuritySvc.isAuthorised('Security.Components.ChangeStatus', AccessTypes.readWrite);

        this.wnd.processing = true;
        this.wnd.onClose = () => this.closeWindow();

        try {

            this.acceptedFileTypes = this.fileManagement.getAcceptedFileExtensions([FileType.document, FileType.pdf, FileType.text, FileType.spreadsheet, FileType.image]);

            this.canViewComponentFailureReport = this.ocSecuritySvc.isAuthorised('Security.Reports.ComponentFailure', AccessTypes.readOnly);        

            if (this.$stateParams && this.$stateParams.initParams)
                this.initParams = this.$stateParams.initParams;

            this.equipmentId = this.initParams.equipmentId;
            this.equipmentEventId = this.initParams.equipmentEventId;
            this.isEdit = this.initParams.isEdit;
            this.isBulkMove = this.initParams.isBulkMove;
            this.isChangeStatus = this.initParams.isChangeStatus;
            this.siteId = this.initParams.siteId;
            this.clientId = this.initParams.clientId;
            this.bulkMoveTargetStatusCategory = this.initParams.bulkMoveTargetStatusCategory;
            this.existingStatusDetails = this.initParams.existingStatusDetails;
            this.defaultEventDate = this.initParams.defaultEventDate;

            this.WindowFactory.addButton(this,
                !this.isEdit || this.isBulkMove ? 'common.save_label' : 'component.updateStatusChangeEvent',
                'saveButton',
                () => this.save(false),
                true
            ); // save button

            this.WindowFactory.addButton(this, 'component.undoEvent', 'undoButton', () => this.onUndo()); // undo button
            this.WindowFactory.addButton(this, 'component.saveAndNext', 'nextButton', () => this.save(true)); // save and next button
            this.WindowFactory.addButton(this, 'common.cancel_label', 'cancelButton', () => this.closeWindow()); // cancel button

            // if we are loading a specific event (the one being edited, or the previous)
            if (this.equipmentEventId) {

                if (this.existingStatusDetails == null) {
                    await this.loadStatusDetails();
                } else {
                    this.statusDetails = this.existingStatusDetails;
                }

                // get status types
                if (!this.isBulkMove) {

                    await this.getStatusTypes();

                    if (this.isEdit) {
                        this.statusDetails.statusType = _.find(this.statusTypes, st => {
                            return st.id === (this.statusDetails.moveDetails.previousStatusTypeId ? this.statusDetails.moveDetails.previousStatusTypeId : this.statusDetails.statusTypeId);
                        });
                        if (this.statusDetails.moveDetails.purchaseOrder) {
                            //this is the selected Purchase Order sent back to the server on save
                            this.statusDetails.moveDetails.purchaseOrderId = this.statusDetails.moveDetails.purchaseOrder.key;

                            //this is the PO set for this equip at load time, it should not change later (doing so will reload the PO list)
                            //it is used to ensure the original PO shows even if it is now fufilled
                            this.initialPurchaseOrderId = this.statusDetails.moveDetails.purchaseOrder.key

                            this.statusDetails.moveDetails.purchaseOrderNumber = "";
                        } else {
                            if (this.statusDetails.moveDetails.purchaseOrderNumber) {
                                this.initialPurchaseOrderId = null;
                                this.statusDetails.moveDetails.purchaseOrderId = "OTHER";
                            } else {
                                this.initialPurchaseOrderId = null;
                                this.statusDetails.moveDetails.purchaseOrderId = null;
                                this.statusDetails.moveDetails.purchaseOrderNumber = "";
                            }
                        }
                    }
                }
            }

            await this.loadReferenceData();

            if (this.statusDetails.moveDetails.damageCauseId) {
                if (this.damageLocations_all)
                    this.damageLocations = this.damageLocations_all.filter(dl => dl.damageCauseId === this.statusDetails.moveDetails.damageCauseId);

                if (this.damageSources_all)
                    this.damageSources = this.damageSources_all.filter(ds => ds.damageCauseId === this.statusDetails.moveDetails.damageCauseId);
            }

            this.status = ScreenStatus.ready;

            this.$timeout(() => this.setPristine());

        } catch (error) {
            this.status = ScreenStatus.error;
        } finally {
            this.wnd.processing = false;
        }
    }

    loadReferenceData(forceReload?: boolean) {

        let referenceDataPromises: Promise<any>[] = [];
        let currencyTypeInPromise = false;

        if (this.statusDetails && this.statusDetails.statusType) {

            if (this.statusDetails.statusType.requiresDestination && (!this.destinations || forceReload)) {
                referenceDataPromises.push(this.getDestinations());
            }

            if (this.statusDetails.statusType.allowsReturnToSupplierReason && (!this.returnToSupplierReasons || forceReload)) {
                referenceDataPromises.push(this.getReturnToSupplierReasons());
            }

            if (this.statusDetails.statusType.allowsLocation && (!this.locations || forceReload)) {
                referenceDataPromises.push(this.getLocations());
            }

            if ((this.statusDetails.statusType.allowsRetreader
                || (this.statusDetails.moveDetails.isReceive && (this.statusDetails.moveDetails.isRetreadReceive
                    || this.statusDetails.statusType.statusTypeFromName == this.enums.statusTypes.dispatchedRetread)))
                && (!this.retreaders || forceReload)) {

                referenceDataPromises.push(this.getRetreaders());
            }

            if ((this.statusDetails.statusType.allowsRepairer
                || (this.statusDetails.moveDetails.isReceive && this.statusDetails.statusType.statusTypeFromName == this.enums.statusTypes.dispatchedRepair))
                && (!this.repairers || forceReload)) {

                referenceDataPromises.push(this.getRepairers());
            }

            if (this.statusDetails.statusType.allowsSupplier && (!this.suppliers || forceReload)) {
                referenceDataPromises.push(this.getSuppliers());
            }

            if ((this.statusDetails.statusType.receivesCredit || this.statusDetails.statusType.requiresCredit || this.statusDetails.statusType.allowsMWClaim) && !this.currencyTypes) {
                referenceDataPromises.push(this.getCurrencyTypes());
                currencyTypeInPromise = true;
            }

            if (this.statusDetails.statusType.allowsRepairType && !this.repairTypes) {
                referenceDataPromises.push(this.getRepairTypes());
            }

            if (this.statusDetails.statusType.requiresDamageReason ||
                (this.statusDetails.moveDetails && this.statusDetails.moveDetails.returnToSupplierReason && this.statusDetails.moveDetails.returnToSupplierReason.requiresDamageReason)) {

                if (!this.damageCauses || forceReload) {
                    referenceDataPromises.push(this.getDamageCauses());
                }

                if (!this.damageLocations_all || forceReload) {
                    referenceDataPromises.push(this.getDamageLocations());
                }

                if (!this.damageSources_all || forceReload) {
                    referenceDataPromises.push(this.getDamageSources());
                }
            }

            if (this.statusDetails.moveDetails) {

                if (this.statusDetails.moveDetails.isReceive && !this.purchaseTypes) {
                    referenceDataPromises.push(this.getPurchaseTypes());
                }

                if (this.statusDetails.moveDetails.isReceive && !currencyTypeInPromise && !this.currencyTypes) {
                    referenceDataPromises.push(this.getCurrencyTypes());
                }
            }
        }

        return Promise.all(referenceDataPromises);
    }

    // status types
    async getStatusTypes() {

        try {
            let criteria: IStatusFlowCriteria = {
                equipmentTypeId: this.statusDetails.componentTypeId,
                statusTypeFromId: (this.statusDetails.currentStatusType ? this.statusDetails.currentStatusType.id : null),
                statusTypeToId: (this.statusDetails.currentStatusType ? null : this.statusDetails.statusTypeId),
                directStatusChange: !this.statusDetails.moveDetails.isReceive,
                atDate: this.ocDateSvc.dateTimeAsUTC(this.statusDetails.eventDate),
                siteId: this.siteId
            };

            this.statusTypes = (await this.dataBroker.getStatusFlows(criteria)).result;

        } catch (error) {
            this.errorReporter.logError(error, 'ChangeStatus-GetStatusTypes');
        }
    }

    // damage causes
    async getDamageCauses() {

        try {
            let criteria: IDamageCauseCriteria = {
                componentTypeId: this.statusDetails.componentTypeId,
                atDate: this.ocDateSvc.dateTimeAsUTC(this.statusDetails.eventDate)
            };

            this.damageCauses = (await this.dataBroker.getDamageCauses(criteria)).result;

            if (this.statusDetails.moveDetails.damageCause && !this.damageCauses.some(r => r.id === this.statusDetails.moveDetails.damageCause.id)) {

                this.statusDetails.moveDetails.damageCause = null;
                this.statusDetails.moveDetails.damageCauseId = undefined;

                if ((this.damageCauseControl || {}).clear)
                    this.damageCauseControl.clear();
            }

        } catch (error) {
            this.errorReporter.logError(error, 'ChangeStatus-GetDamageCauses');
        }
    }

    // damage locations
    async getDamageLocations() {

        try {
            let criteria: IDamageLocationCriteria = {
                componentTypeId: this.statusDetails.componentTypeId,
                atDate: this.ocDateSvc.dateTimeAsUTC(this.statusDetails.eventDate)
            };

            this.damageLocations_all = (await this.dataBroker.getDamageLocations(criteria)).result;

            if (this.statusDetails.moveDetails.damageLocation && !this.damageLocations_all.some(r => r.id === this.statusDetails.moveDetails.damageLocation.id)) {

                this.statusDetails.moveDetails.damageLocation = null;
                this.statusDetails.moveDetails.damageLocationId = undefined;

                if ((this.damageLocationControl || {}).clear)
                    this.damageLocationControl.clear();
            }

        } catch (error) {
            this.errorReporter.logError(error, 'ChangeStatus-GetDamageLocations');
        }
    }

    // damage sources
    async getDamageSources() {

        try {
            let criteria: IDamageSourceCriteria = {
                componentTypeId: this.statusDetails.componentTypeId,
                atDate: this.ocDateSvc.dateTimeAsUTC(this.statusDetails.eventDate)
            };

            this.damageSources_all = (await this.dataBroker.getDamageSources(criteria)).result;

            if (this.statusDetails.moveDetails.damageSource && !this.damageSources_all.some(r => r.id === this.statusDetails.moveDetails.damageSource.id)) {

                this.statusDetails.moveDetails.damageSource = null;
                this.statusDetails.moveDetails.damageSourceId = undefined;

                if ((this.damageSourceControl || {}).clear) {
                    this.damageSourceControl.clear();
                }
            }
        } catch (error) {
            this.errorReporter.logError(error, 'ChangeStatus-GetDamageSources');
        }
    }

    // locations
    async getLocations() {

        try {

            //TODO: is this expecting this.statusDetails.eventDate as localtime/localtime as UTC/UTC?
            let criteria: ILocationCriteria = {
                siteIds: [this.siteId],
                date: this.statusDetails.eventDate,
                statusTypeIds: [this.statusDetails.statusType ? this.statusDetails.statusType.statusTypeIdTo : this.statusDetails.statusTypeId]
            };

            this.locations = (await this.dataBroker.getLocations(criteria)).result;

            if (this.statusDetails.moveDetails.location && !this.locations.some(r => r.key === this.helperSvc.getKey(this.statusDetails.moveDetails.location))) {
                this.statusDetails.moveDetails.location = null;
            }

        } catch (error) {
            this.errorReporter.logError(error, 'ChangeStatus-GetLocations');
        }
    }

    // destinations
    async getDestinations() {

        try {

            let sitesCriteria: ISitesCriteria = {
                allowEmptyClientFilter: true,
                includeOtherOption: true,
                excludedSiteIds: [this.siteId],
                showAllLocations: false
            };

            if (this.clientId) {
                sitesCriteria.clientIds = [this.clientId];
                sitesCriteria.showAllLocations = true;
            }

            let response = await this.amtCommandQuerySvc.get(this.adminUrls.getSitesCQS, sitesCriteria);

            this.destinations = (response.data || {}).result;

        } catch (error) {
            this.errorReporter.logError(error, 'ChangeStatus-GetDestinations');
        }
    }

    // purchase types
    async getPurchaseTypes() {
        try {
            this.purchaseTypes = (await this.dataBroker.getCostTypes()).result;
        } catch (error) {
            this.errorReporter.logError(error, 'ChangeStatus-GetPurchaseTypes');
        }
    }

    // retreaders
    async getRetreaders() {

        try {

            let criteria: IRetreaderCriteria = {
                siteId: this.siteId,
                includeOther: true,
                equipmentTypeIds: [this.statusDetails.componentTypeId]
            };

            this.retreaders = (await this.dataBroker.getRetreaders(criteria)).result;

            if (this.statusDetails.moveDetails.retreader && !this.retreaders.some(r => r.key === this.helperSvc.getKey(this.statusDetails.moveDetails.retreader))) {
                this.statusDetails.moveDetails.retreader = null;
            }
        } catch (error) {
            this.errorReporter.logError(error, 'ChangeStatus-GetRetreaders');
        }
    }

    // repairers
    async getRepairers() {

        try {

            let criteria: IRepairerCriteria = {
                siteId: this.siteId,
                includeOther: true,
                equipmentTypeIds: [this.statusDetails.componentTypeId]
            };

            this.repairers = (await this.dataBroker.getRepairers(criteria)).result;

            if (this.statusDetails.moveDetails.repairer && !this.repairers.some(r => r.key === this.helperSvc.getKey(this.statusDetails.moveDetails.repairer))) {
                this.statusDetails.moveDetails.repairer = null;
            }
        } catch (error) {
            this.errorReporter.logError(error, 'ChangeStatus-GetRepairers');
        }
    }

    // suppliers
    async getSuppliers() {

        try {

            let criteria: ISupplierCriteria = {
                siteId: this.siteId,
                includeOther: true,
                equipmentTypeIds: [this.statusDetails.componentTypeId]
            };

            this.suppliers = (await this.dataBroker.getSuppliers(criteria)).result;

            if (this.statusDetails.moveDetails.supplier && !this.suppliers.some(r => r.key === this.helperSvc.getKey(this.statusDetails.moveDetails.supplier))) {
                this.statusDetails.moveDetails.supplier = null;
            }

        } catch (error) {
            this.errorReporter.logError(error, 'ChangeStatus-GetSuppliers');
        }
    }

    // currency types
    async getCurrencyTypes() {

        try {

            let criteria: ICurrencyCriteria = {
                siteId: this.siteId
            };

            this.currencyTypes = (await this.dataBroker.getCurrencyTypes(criteria)).result;

        } catch (error) {
            this.errorReporter.logError(error, 'ChangeStatus-GetCurrencyTypes');
        }
    }

    // repair types
    async getRepairTypes() {

        try {

            let criteria: IRepairTypeCriteria = {
                siteId: this.siteId
            };

            this.repairTypes = (await this.dataBroker.getRepairTypes(criteria)).result;

        } catch (error) {
            this.errorReporter.logError(error, 'ChangeStatus-GetRepairTypes');
        }
    }

    // return to supplier reasons
    async getReturnToSupplierReasons() {

        try {

            let criteria: IReturnToSupplierReasonCriteria = {
                siteId: this.siteId,
                includeBlank: true
            };

            this.returnToSupplierReasons = (await this.dataBroker.getReturnToSupplierReasons(criteria)).result

            if (this.statusDetails.moveDetails.returnToSupplierReason && !this.returnToSupplierReasons.some(r => r.key === this.helperSvc.getKey(this.statusDetails.moveDetails.returnToSupplierReason))) {
                this.statusDetails.moveDetails.returnToSupplierReason = null;
            }

        } catch (error) {
            this.errorReporter.logError(error, 'ChangeStatus-GetReturnToSupplierReasons');
        }
    }

    // core functions (load, save, undo)

    // load status details
    async loadStatusDetails() {

        try {

            let criteria = {
                equipmentEventId: this.equipmentEventId
            };

            this.statusDetails = await this.amtCommandQuerySvc.post(this.componentUrls.getComponentEventDetails, criteria);

            this.statusDetails.secondHandPreviousSite = this.statusDetails.client === this.amtConstants.secondHandPreviousClient && this.statusDetails.site === this.amtConstants.secondHandPreviousSite;

            this.statusDetails.hasWarrantyClaim = this.statusDetails.moveDetails.warrantyClaim !== null;

            if (!this.statusDetails.moveDetails.destinationId) {
                this.statusDetails.moveDetails.destinationId = this.statusDetails.moveDetails.destinationOther ? this.amtConstants.emptyGuid : null;
            }

            if (!this.isEdit && this.statusDetails.moveDetails.previousStatusTypeId == null) {
                this.statusDetails.moveDetails.previousStatusTypeId = this.statusDetails.statusTypeId;
            }

            if (!this.statusDetails.hasWarrantyClaim) {
                this.statusDetails.moveDetails.warrantyClaim = {
                    currencyType: {
                        id: this.ocConfigSvc.user.site.currency.id,
                        symbol: this.ocConfigSvc.user.site.currency.symbol,
                        decimalPlaces: this.ocConfigSvc.user.site.currency.decimalPlaces
                    },
                    amount: 0
                };
            }

            let readings: IReading[] = this.statusDetails.readings;

            // check for readings and populate the remaining depth fields if values exist
            if (readings !== null) {

                let depthReading = readings.filter(reading => reading.type.toLowerCase() === ReadingTypeName.treadDepth || reading.type.toLowerCase() === ReadingTypeName.chainDepth)[0];

                if (depthReading) {
                    let depthValues = depthReading.readingValues;

                    if (depthValues !== null) {
                        this.statusDetails.remainingDepthInner = depthValues.find(rv => rv.sequence === 1).value;
                        this.statusDetails.remainingDepthOuter = depthValues.find(rv => rv.sequence === 2).value;
                        this.statusDetails.depthNewMeter = depthReading.isNewMeter;
                    }
                }

                // don't roll these over to new status changes
                if (this.isEdit) {

                    let hoursReadings = readings.filter(r => r.type.toLowerCase() === ReadingTypeName.hours);

                    if (hoursReadings.length > 0) {

                        hoursReadings = _.sortBy(hoursReadings, r => r.date).reverse();

                        // hours reading, or final hours reading in the case of an ndt
                        this.statusDetails.hours = hoursReadings[0].value;
                        this.statusDetails.hoursNewMeter = hoursReadings[0].isNewMeter;
                    }

                    let distanceReading = readings.find(r => r.type.toLowerCase() === ReadingTypeName.distance);

                    if (distanceReading) {
                        this.statusDetails.distance = distanceReading.value;
                        this.statusDetails.distanceNewMeter = distanceReading.isNewMeter;
                    }

                    let ndtReading = readings.find(r => r.type.toLowerCase() === ReadingTypeName.ndt);

                    if (ndtReading) {

                        this.statusDetails.ndt = this.ndtStatuses.find(ndt => ndt.key === ndtReading.value);

                        if (hoursReadings.length > 1) {
                            // check for a first hours reading
                            this.statusDetails.hoursSinceLastNdt = hoursReadings[0].difference;
                        }
                    }

                    // primary otd
                    let primaryOTDreading = readings.find(r => r.type.toLowerCase() === ReadingTypeName.primaryOtd);

                    if (primaryOTDreading) {
                        this.statusDetails.primaryOTD = primaryOTDreading;
                    }

                    // life otd
                    let lifeOTDreading = readings.find(r => r.type.toLowerCase() === ReadingTypeName.lifeOtd);

                    if (lifeOTDreading) {
                        this.statusDetails.lifeOTD = lifeOTDreading;
                    }
                }
            }

            if (this.isBulkMove) {

                this.statusDetails.currentStatusType = {
                    id: this.statusDetails.statusTypeId,
                    description: this.statusDetails.statusTypeDescription
                };

                // don't default comment or attachments
                this.statusDetails.comments = null;
                this.statusDetails.attachments = [];

                let criteria = {
                    statusTypeFromId: this.statusDetails.statusTypeId,
                    bulkMoveTargetStatusCategory: this.bulkMoveTargetStatusCategory,
                    equipmentTypeId: this.statusDetails.componentTypeId
                };

                let statusType = await this.amtCommandQuerySvc.post(this.componentUrls.resolveBulkMoveStatusType, criteria);

                this.statusDetails.statusType = statusType;
                this.statusDetails.statusTypeId = statusType.id;
                this.statusDetails.statusTypeDescription = statusType.description;

            } else if (!this.isEdit) {

                this.statusDetails.currentStatusType = {
                    id: this.statusDetails.statusTypeId,
                    description: this.statusDetails.statusTypeDescription
                };

                this.statusDetails.statusType = null;
                this.statusDetails.comments = null;
                this.statusDetails.attachments = [];
                this.statusDetails.moveDetails.isReceive = false;
                this.statusDetails.moveDetails.isSecondHandOrOpeningBalanceReceive = false;
                this.statusDetails.moveDetails.isRetreadReceive = false;
                this.statusDetails.eventDate = null;
            }

            if (this.defaultEventDate !== null && this.defaultEventDate !== undefined) {
                this.statusDetails.eventDate = this.ocDateSvc.dateTimeAsUTC(this.defaultEventDate);
            }

            if (this.readonly) { this.isEdit = true; }
        } catch (error) {

            this.status = this.enums.screenStatus.error;

            this.statusDetails = { errors: [error] };

            this.errorReporter.logError(error, 'ChangeStatus-LoadDetails');
        }
    }

    // undo event
    async onUndo() {

        try {

            await this.confirmSvc.confirmMessage('component.confirmUndo_title', 'component.confirmUndo');

            this.wnd.processing = true;

            let cmd = {
                equipmentEventId: this.equipmentEventId
            };

            this.undoComponentStatus(cmd);

        } catch (error) {
            // error or cancelled
            if (error) this.errorReporter.logError(error, 'ChangeStatus-UndoEvent');
        } finally {
            this.wnd.processing = false;
        }
    }

    async undoComponentStatus(cmd) {

        await this.amtCommandQuerySvc.post(this.componentUrls.undoMoveEvents, [cmd]);

        this.notifySvc.success(this.amtXlatSvc.xlat("component.messageUndoComponentStatusSuccess"));

        if (this.form)
            this.form.$dirty = false;

        if (this.wnd.onDataChanged)
            this.wnd.onDataChanged();

        this.$rootScope.$broadcast('filterSearch');

        // check if the component still exists and then refresh the component details window if open
        let exists = await this.amtCommandQuerySvc.get(this.equipmentUrls.url.checkEquipmentExists, { id: this.equipmentId }).data;

        this.$rootScope.$broadcast('refreshEquipmentDetails', this.equipmentId, exists);

        this.setPristine();

        this.closeWindow();
    }

    // save event / change status
    async save(moveNext: boolean) {

        if (new Date(this.statusDetails.eventDate) > this.ocDateSvc.dateTimeAsUTC(this.ocDateSvc.now())) {
            this.notifySvc.error(this.amtXlatSvc.xlat("equipment.eventDateCannotOccurInFuture"));
            return;
        }

        if (this.hasHoursSinceLastNDT && this.statusDetails.hoursSinceLastNdt > this.statusDetails.hours) {
            this.notifySvc.error(this.amtXlatSvc.xlat("exception.reading_hoursSinceNdtCannotExceedTotalHours"));
            return;
        }

        this.wnd.processing = true;

        try {

            if (!this.isBulkMove) {

                for (let attachment of this.statusDetails.attachments || [])
                    attachment.source = AttachmentType.equipmentEventAttachment;

                await this.fileManagement.processFileUploads(this.statusDetails.attachments);

                let includeRepairDetails = this.statusDetails.statusType.allowsRepairer
                    || this.statusDetails.statusType.allowsRepairType
                    || (this.statusDetails.moveDetails.isReceive
                        && this.statusDetails.statusType.statusTypeFromName == this.enums.statusTypes.dispatchedRepair);

                let includeRetreadDetails = this.statusDetails.statusType.allowsRetreader
                    || (this.statusDetails.moveDetails.isReceive && (this.statusDetails.moveDetails.isRetreadReceive
                        || this.statusDetails.statusType.statusTypeFromName == this.enums.statusTypes.dispatchedRetread));

                // second-hand/opening balance and value entered for hours since last ndt, or other receive and ndt result provided
                let hasNdt = (this.statusDetails.moveDetails.isSecondHandOrOpeningBalanceReceive && this.hasHoursSinceLastNDT)
                    || (!this.statusDetails.moveDetails.isSecondHandOrOpeningBalanceReceive && this.statusDetails.ndt); 

                let saveCriteria = {
                    equipmentEventId: this.equipmentEventId,
                    eventDate: this.ocDateSvc.dateTimeAsUTC(this.statusDetails.eventDate),
                    comment: this.statusDetails.comments,
                    isEdit: this.isEdit,
                    siteId: this.siteId,
                    equipmentId: this.equipmentId,
                    attachments: this.statusDetails.attachments,
                    movementDetails: {
                        locationId: this.helperSvc.getKey(this.statusDetails.moveDetails.location),
                        isFittedTransfer: this.statusDetails.statusType.isFittedTransfer,
                        newStatusTypeId: (this.statusDetails.statusType ? this.statusDetails.statusType.statusTypeIdTo : this.statusDetails.statusTypeId),
                        damageDetails: (this.statusDetails.moveDetails.damageCause !== null ? {
                            damageCauseId: this.helperSvc.getKey(this.statusDetails.moveDetails.damageCause),
                            damageLocationId: this.helperSvc.getKey(this.statusDetails.moveDetails.damageLocation),
                            damageSourceId: this.helperSvc.getKey(this.statusDetails.moveDetails.damageSource),
                            instant: this.statusDetails.moveDetails.damageCauseInstant,
                            tyreExplosion: this.statusDetails.moveDetails.damageCauseTyreExplosion,
                            tyreBurst: this.statusDetails.moveDetails.damageCauseTyreBurst
                        } : null),

                        transferDetails: (this.statusDetails.statusType.requiresDestination ? {
                            destinationSiteId: this.helperSvc.getKey(this.statusDetails.moveDetails.destination),
                            destinationOther: this.statusDetails.moveDetails.destinationOther
                        } : null),

                        warrantyClaimDetails: (this.statusDetails.hasWarrantyClaim ? {
                            number: this.statusDetails.moveDetails.warrantyClaim.number,
                            amount: this.statusDetails.moveDetails.warrantyClaim.amount,
                            currencyTypeId: this.helperSvc.getKey(this.statusDetails.moveDetails.warrantyClaim.currencyType),
                            date: this.ocDateSvc.dateTimeAsUTC(this.statusDetails.eventDate)
                        } : null),

                        returnToSupplierDetails: (this.statusDetails.statusType.allowsReturnToSupplierReason ? {
                            returnToSupplierReasonId: this.helperSvc.getKey(this.statusDetails.moveDetails.returnToSupplierReason),
                            returnToSupplierReasonOther: this.statusDetails.moveDetails.returnToSupplierReasonOther,
                            supplierId: this.helperSvc.getKey(this.statusDetails.moveDetails.supplier),
                            supplierOther: this.statusDetails.moveDetails.supplierOther
                        } : null),

                        repairDetails: includeRepairDetails ? {
                            repairerId: this.helperSvc.getKey(this.statusDetails.moveDetails.repairer),
                            repairerOther: this.statusDetails.moveDetails.repairerOther,
                            repairTypeId: this.helperSvc.getKey(this.statusDetails.moveDetails.repairType) || this.statusDetails.moveDetails.repairTypeId
                        } : null,

                        retreadDetails: includeRetreadDetails ? {
                            retreaderId: this.helperSvc.getKey(this.statusDetails.moveDetails.retreader),
                            retreaderOther: this.statusDetails.moveDetails.retreaderOther
                        } : null,

                        dispatchDetails: (this.statusDetails.statusType.isDispatch ? {
                            consignmentNumber: this.statusDetails.moveDetails.consignmentNumber
                        } : null),

                        receiveDetails: (this.statusDetails.moveDetails.isReceive ? {
                            purchaseOrderNumber: this.statusDetails.moveDetails.purchaseOrderId == "OTHER" ? this.statusDetails.moveDetails.purchaseOrderNumber : "",
                            purchaseOrderId: this.statusDetails.moveDetails.purchaseOrderId,
                            invoiceReference: this.statusDetails.moveDetails.invoiceReference,
                            hoursSinceLastNdt: this.statusDetails.hoursSinceLastNdt,
                            ndtResult: hasNdt ? this.helperSvc.getKey(this.statusDetails.ndt) || NdtStatus.pass /* defaulted to pass if not provided */ : null
                        } : null)
                    },
                    costDetails: (this.statusDetails.moveDetails.associatedCost !== null && (this.statusDetails.moveDetails.associatedCost.amount !== null && this.statusDetails.moveDetails.associatedCost.currencyType !== null) ? {
                        amount: this.statusDetails.moveDetails.associatedCost.amount,
                        costTypeId: (this.statusDetails.moveDetails.associatedCost.costType !== null ? this.helperSvc.getKey(this.statusDetails.moveDetails.associatedCost.costType) : null), // should be operational expenditure
                        currencyTypeId: this.helperSvc.getKey(this.statusDetails.moveDetails.associatedCost.currencyType)
                    } : null),

                    readingDetails: {
                        readings: []
                    }
                };
                
                // remaining depth
                if (this.statusDetails.remainingDepthInner != null) {

                    var avgDepth = (this.statusDetails.remainingDepthInner + this.statusDetails.remainingDepthOuter) / 2;

                    saveCriteria.readingDetails.readings.push({
                        readingEventTypeName: this.statusDetails.equipmentTypeName.toLowerCase() === EquipmentTypeName.chain ? ReadingTypeName.chainDepth : ReadingTypeName.treadDepth,
                        isNewMeter: (this.statusDetails.depthNewMeter !== null && this.statusDetails.depthNewMeter !== undefined ? this.statusDetails.depthNewMeter : false),
                        unitOfMeasureAbbreviation: this.ocConfigSvc.user.site.units.depth.unitAbbreviation,
                        values: [{
                            value: this.statusDetails.remainingDepthInner,
                            sequence: 1
                        }, {
                            value: this.statusDetails.remainingDepthOuter,
                            sequence: 2
                        }]
                    });

                    // primary otd
                    if (this.statusDetails.primaryOTD) {
                        saveCriteria.readingDetails.readings.push({
                            readingEventTypeName: ReadingTypeName.primaryOtd,
                            isNewMeter: this.statusDetails.primaryOTD.isNewMeter,
                            unitOfMeasureAbbreviation: this.statusDetails.primaryOTD.unit,
                            values: [{
                                value: (this.statusDetails.moveDetails.isRetreadReceive ? avgDepth : this.statusDetails.primaryOTD.value),
                                sequence: 1
                            }]
                        });
                    }

                    // life otd
                    if (this.statusDetails.lifeOTD) {
                        saveCriteria.readingDetails.readings.push({
                            readingEventTypeName: ReadingTypeName.lifeOtd,
                            isNewMeter: this.statusDetails.lifeOTD.isNewMeter,
                            unitOfMeasureAbbreviation: this.statusDetails.lifeOTD.unit,
                            values: [{
                                value: this.statusDetails.remainingDepthInner,
                                sequence: 1
                            }, {
                                value: this.statusDetails.remainingDepthOuter,
                                sequence: 2
                            }]
                        });
                    }
                }

                // hours
                if (this.statusDetails.hours != null) {
                    saveCriteria.readingDetails.readings.push({
                        readingEventTypeName: ReadingTypeName.hours,
                        isNewMeter: this.statusDetails.hoursNewMeter,
                        unitOfMeasureAbbreviation: this.ocConfigSvc.user.site.units.time.unitAbbreviation,
                        values: [{
                            value: this.hasHoursSinceLastNDT ? this.statusDetails.hours - this.statusDetails.hoursSinceLastNdt : this.statusDetails.hours,
                            sequence: 1
                        }]
                    });
                }

                // distance
                if (this.statusDetails.distance != null) {
                    saveCriteria.readingDetails.readings.push({
                        readingEventTypeName: ReadingTypeName.distance,
                        isNewMeter: this.statusDetails.distanceNewMeter,
                        unitOfMeasureAbbreviation: this.ocConfigSvc.user.site.units.distance.unitAbbreviation,
                        values: [{
                            value: this.statusDetails.distance,
                            sequence: 1
                        }]
                    });
                }

                // ndt
                if (this.statusDetails.ndt != null && !this.statusDetails.moveDetails.isSecondHandOrOpeningBalanceReceive) {
                    saveCriteria.readingDetails.readings.push({
                        readingEventTypeName: ReadingTypeName.ndt,
                        isNewMeter: false,
                        unitOfMeasureAbbreviation: this.ocConfigSvc.user.site.units.boolean.unitAbbreviation,
                        values: [{
                            value: this.statusDetails.ndt.key,
                            sequence: 1
                        }]
                    });
                }

                await this.amtCommandQuerySvc.post(this.componentUrls.addUpdateMoveEvent, saveCriteria, true);

                this.notifySvc.success(this.amtXlatSvc.xlat("component.messageComponentStatusUpdateSuccess"));

                if (this.wnd.onDataChanged)
                    this.wnd.onDataChanged();

                this.$rootScope.$broadcast('filterSearch');

                if (this.statusDetails.generateComponentFailureReport)
                    this.reportSvc.downloadComponentFailureReports([this.statusDetails.serialNumber.manufacturer]);

                this.setPristine();
                this.closeWindow();

            } else {

                this.statusDetails.readings = this.statusDetails.remainingDepthInner != null ? [{
                    isNewMeter: false,
                    unit: this.ocConfigSvc.user.site.units.depth.unitAbbreviation,
                    readingValues: [{
                        value: this.statusDetails.remainingDepthInner,
                        sequence: 1
                    }, {
                        value: this.statusDetails.remainingDepthOuter,
                        sequence: 2
                    }]
                }] : null;

                if (!this.statusDetails.hasWarrantyClaim) {
                    this.statusDetails.moveDetails.warrantyClaim = null;
                }

                this.$timeout(() => {

                    if (this.wnd.onDataChanged) {
                        this.wnd.onDataChanged({
                            text: {
                                details: this.statusDetails,
                                goToNext: moveNext
                            }
                        });
                    }

                    this.setPristine();
                    this.closeWindow();
                });
            }
        } catch (error) {
            this.errorReporter.logError(error, 'ChangeStatus-Save');
        } finally {
            this.wnd.processing = false;
        }
    }

    async closeWindow() {
        try {
            await this.confirmSvc.confirmSaveChange(this.form.$dirty);
        } catch (error) {
            return; // they cancelled
        }

        this.setPristine();

        this.WindowFactory.closeWindow(this.wnd);
    }

    setPristine() {
        if (this.form) this.form.$setPristine()
    }

    onRetreaderChange(option: IRetreader) {
        if (!option || option.requiresManualEntry === false) {
            this.statusDetails.moveDetails.retreaderOther = null;
        }
    }

    onRepairerChange(option: IRepairer) {
        if (!option || option.requiresManualEntry === false) {
            this.statusDetails.moveDetails.repairerOther = null;
        }
    }

    onSupplierChange(option: ISupplier) {
        if (!option || option.requiresManualEntry === false) {
            this.statusDetails.moveDetails.supplierOther = null;
        }
    }

    onDestinationChange(option: any) {
        if (!option || option.requiresManualEntry === false) {
            this.statusDetails.moveDetails.destinationOther = null;
        }

        if (this.statusDetails.moveDetails != null) {
            this.statusDetails.moveDetails.destinationId = this.helperSvc.getKey(option);
        }
    }

    onDamageCauseChange(option: IDamageCause) {
        if (this.statusDetails.moveDetails.damageCause != option) {

            this.damageLocations = this.damageLocations_all.filter(dl => dl.damageCauseId === this.helperSvc.getKey(option));
            this.damageSources = this.damageSources_all.filter(ds => ds.damageCauseId === this.helperSvc.getKey(option));

            this.statusDetails.moveDetails.damageLocation = null;
            this.statusDetails.moveDetails.damageSource = null;

            this.statusDetails.moveDetails.damageLocationId = null;
            this.statusDetails.moveDetails.damageSourceId = null;

            if ((this.damageLocationControl || {}).clear) {
                this.damageLocationControl.clear();
            }

            if ((this.damageSourceControl || {}).clear) {
                this.damageSourceControl.clear();
            }
        }
    }

    onWarrantyClaimCheckChange() {
        if (!this.statusDetails.hasWarrantyClaim) {
            this.statusDetails.moveDetails.warrantyClaim = null;
        } else {
            this.statusDetails.moveDetails.warrantyClaim = {
                currencyType: {
                    key: this.ocConfigSvc.user.site.currency.id,
                    symbol: this.ocConfigSvc.user.site.currency.symbol,
                    decimalPlaces: this.ocConfigSvc.user.site.currency.decimalPlaces
                },
                amount: 0
            };
        }
    }

    private clearDamageReason() {

        this.statusDetails.moveDetails.damageCause = null;
        this.statusDetails.moveDetails.damageLocation = null;
        this.statusDetails.moveDetails.damageSource = null;

        this.statusDetails.moveDetails.damageCauseId = null;
        this.statusDetails.moveDetails.damageLocationId = null;
        this.statusDetails.moveDetails.damageSourceId = null;

        if ((this.damageCauseControl || {}).clear) {
            this.damageCauseControl.clear();
        }

        if ((this.damageLocationControl || {}).clear) {
            this.damageLocationControl.clear();
        }

        if ((this.damageSourceControl || {}).clear) {
            this.damageSourceControl.clear();
        }

        this.statusDetails.moveDetails.damageCauseInstant = null;
        this.statusDetails.moveDetails.damageCauseTyreBurst = null;
        this.statusDetails.moveDetails.damageCauseTyreExplosion = null;
    }

    // warranty claims
    async generateClaimNumber() {

        this.generatingClaimNumber = true;

        try {
            let criteria = {
                siteId: this.siteId
            };

            this.statusDetails.moveDetails.warrantyClaim.number = await this.amtCommandQuerySvc.post(this.componentUrls.generateWarrantyClaimNumber, criteria);

            this.$timeout(() => this.form.$setDirty()); //OR-10257

        } catch (error) {
            this.errorReporter.logError(error, 'ChangeStatus-GenerateClaimNumber');
        } finally {
            this.generatingClaimNumber = false;
        }
    }

    evaluateButtons() {
        this.buttonStates.saveButton.visible = !this.readonly;
        this.buttonStates.saveButton.disabled = this.form.$invalid || this.form.$pristine || this.readonly || this.wnd.processing;

        this.buttonStates.nextButton.visible = this.isBulkMove && !this.readonly;
        this.buttonStates.nextButton.disabled = this.form.$invalid || this.form.$pristine || this.wnd.processing;

        this.buttonStates.undoButton.visible = this.isEdit && !!this.statusDetails && this.statusDetails.statusTo === null && !this.isBulkMove && !this.readonly && !this.statusDetails.statusType.isFittedTransfer;
        this.buttonStates.undoButton.disabled = this.form.readOnly || this.wnd.processing;
    }
}

class changeStatusPopupComponent implements ng.IComponentOptions {
    public bindings = {
        initParams: '<',
        buttonMethods: '=',
        buttonStates: '=',
        buttons: '=',
        closeOnSave: '=',
        wnd: '='
    };
    public template = tmpl;
    public controller = changeStatusPopupCtrl;
    public controllerAs = 'vm';
}

angular.module('app.equipment').component('changeStatusPopup', new changeStatusPopupComponent());

